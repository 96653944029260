var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Popup',{staticClass:"create-entity",attrs:{"modal-id":_vm.modalId,"on-close":_vm.onModalClose,"size":"lg","custom-class":"gray-header","toggle-status":_vm.toggleStatus},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"create-entity__header-title"},[_vm._v(_vm._s(_vm.title))])]},proxy:true},{key:"body",fn:function(){return [_c('FormulateForm',{staticClass:"create-entity__body",attrs:{"id":"new-entity"},on:{"submit":_vm.submitData}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.requiredFields.includes('title'))?_c('FormulateInput',{class:_vm.formulateClass && _vm.formulateClass.title
                ? _vm.formulateClass.title
                : '',attrs:{"type":"text","name":"title","error-behavior":"blur","validation":"required","label":_vm.titlePlaceholder,"validation-messages":{
              required: _vm.labels.required,
            }}}):_vm._e(),(_vm.requiredFields.includes('text'))?_c('div',[(_vm.kind == 'media')?_c('FormulateInput',{class:_vm.formulateClass && _vm.formulateClass.text
                  ? _vm.formulateClass.text
                  : '',attrs:{"type":"textarea","name":"text","error-behavior":"blur","rows":"5","label":_vm.textPlaceholder}}):_c('FormulateInput',{class:_vm.formulateClass && _vm.formulateClass.text
                  ? _vm.formulateClass.text
                  : '',attrs:{"type":"textarea","name":"text","error-behavior":"blur","validation":"required","rows":"5","label":_vm.textPlaceholder,"validation-messages":{
                required: _vm.labels.required,
              }}})],1):_vm._e(),(_vm.requiredFields.includes('richtext'))?_c('TextEditorCard',{class:_vm.formulateClass && _vm.formulateClass.richtext
                ? _vm.formulateClass.richtext
                : '',attrs:{"error-behavior":"blur","validation":"required","validation-messages":{
              required: _vm.labels.required,
            },"name":"richtext"}}):_vm._e(),(_vm.requiredFields.includes('textarea'))?_c('FormulateInput',{class:_vm.formulateClass && _vm.formulateClass.textarea
                ? _vm.formulateClass.textarea
                : '',attrs:{"type":"textarea","name":"text","error-behavior":"blur","validation":"required","label":_vm.textPlaceholder,"validation-messages":{
              required: _vm.labels.required,
            }}}):_vm._e(),_vm._l((_vm.customFields),function(field,idx){return _c('div',{key:idx},[_c('FormulateInput',{class:field.class ? field.class : '',attrs:{"type":field.type ? field.type : 'text',"name":field.name ? field.name : 'text',"rows":field.row ? field.row : '5',"error-behavior":field.errorBehavior ? field.errorBehavior : 'blur',"label":field.label ? field.label : '',"validation-name":field.validationName
                  ? field.validationName
                  : field.name
                  ? field.name
                  : _vm.labels.field,"validation-messages":field.validationMessages,"validation":_vm.required ? _vm.required : true}})],1)})],2),(!_vm.requiredFields.includes('media'))?_c('div',{class:("col-" + _vm.grid)},[_vm._t("optional-content")],2):_vm._e(),(_vm.requiredFields.includes('media'))?_c('div',{staticClass:"col-12 media"},[_c('p',{staticClass:"media-title"},[_vm._v(_vm._s(_vm.mediaPlaceholder))]),(_vm.selectedFile && _vm.selectedFileType === 'video/mp4')?_c('section',{staticClass:"vision",attrs:{"id":"vision"}},[_c('video',{staticClass:"video",attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"size":"1080","src":_vm.fileUrl,"type":"video/mp4"}})])]):(_vm.selectedFile && _vm.selectedFileType === 'image/png')?_c('img',{staticClass:"media-img",attrs:{"src":_vm.fileUrl}}):(_vm.selectedFile && _vm.selectedFileType === 'audio/mpeg')?_c('img',{staticClass:"media-img",attrs:{"src":"https://cdn.pixabay.com/photo/2017/02/27/20/48/headphones-2104207_960_720.png"}}):_vm._e(),(_vm.requiredFields.includes('media'))?_c('FormulateInput',{attrs:{"type":"file","name":"media","help":_vm.labels.select_media_format,"validation":"required|mime:image/apng,image/avif,image/gif,image/jpeg,image/png,image/svg+xml,image/webp,video/mp4,video/ogv,video/flv,video/webm,video/asf,video/avi,audio/mpeg","validation-messages":{ required: _vm.labels.required }},on:{"change":_vm.onFileChange}}):_vm._e()],1):_vm._e()])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"create-entity__footer"},[_c('FormulateInput',{staticClass:"btn-purple",attrs:{"disabled":_vm.submitBtnDisabled,"type":"submit","label":_vm.labels.create,"form":"new-entity"}}),_c('Button',{staticStyle:{"fontweight":"600"},attrs:{"label":_vm.labels.close,"variant":"btn-outline-purple","data-dismiss":"modal","action":_vm.onModalClose}})],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }