<template>
  <Popup
    class="create-entity"
    :modal-id="modalId"
    :on-close="onModalClose"
    size="lg"
    custom-class="gray-header"
    :toggle-status="toggleStatus"
  >
    <template v-slot:header>
      <div class="create-entity__header-title">{{ title }}</div>
    </template>

    <template v-slot:body>
      <FormulateForm
        id="new-entity"
        @submit="submitData"
        class="create-entity__body"
      >
        <div class="row">
          <div class="col-12">
            <FormulateInput
              v-if="requiredFields.includes('title')"
              type="text"
              name="title"
              :class="
                formulateClass && formulateClass.title
                  ? formulateClass.title
                  : ''
              "
              error-behavior="blur"
              validation="required"
              v-bind:label="titlePlaceholder"
              v-bind:validation-messages="{
                required: labels.required,
              }"
            />
            <div v-if="requiredFields.includes('text')">
              <FormulateInput
                v-if="kind == 'media'"
                type="textarea"
                name="text"
                :class="
                  formulateClass && formulateClass.text
                    ? formulateClass.text
                    : ''
                "
                error-behavior="blur"
                rows="5"
                v-bind:label="textPlaceholder"
              />
              <FormulateInput
                v-else
                type="textarea"
                name="text"
                :class="
                  formulateClass && formulateClass.text
                    ? formulateClass.text
                    : ''
                "
                error-behavior="blur"
                validation="required"
                rows="5"
                v-bind:label="textPlaceholder"
                v-bind:validation-messages="{
                  required: labels.required,
                }"
              />
            </div>

            <TextEditorCard
              v-if="requiredFields.includes('richtext')"
              :class="
                formulateClass && formulateClass.richtext
                  ? formulateClass.richtext
                  : ''
              "
              error-behavior="blur"
              validation="required"
              v-bind:validation-messages="{
                required: labels.required,
              }"
              name="richtext"
            />

            <FormulateInput
              v-if="requiredFields.includes('textarea')"
              type="textarea"
              name="text"
              :class="
                formulateClass && formulateClass.textarea
                  ? formulateClass.textarea
                  : ''
              "
              error-behavior="blur"
              validation="required"
              v-bind:label="textPlaceholder"
              v-bind:validation-messages="{
                required: labels.required,
              }"
            />

            <div v-for="(field, idx) in customFields" :key="idx">
              <FormulateInput
                :type="field.type ? field.type : 'text'"
                :name="field.name ? field.name : 'text'"
                :class="field.class ? field.class : ''"
                :rows="field.row ? field.row : '5'"
                :error-behavior="
                  field.errorBehavior ? field.errorBehavior : 'blur'
                "
                :label="field.label ? field.label : ''"
                :validation-name="
                  field.validationName
                    ? field.validationName
                    : field.name
                    ? field.name
                    : labels.field
                "
                :validation-messages="field.validationMessages"
                :validation="required ? required : true"
              />
            </div>
          </div>

          <div :class="`col-${grid}`" v-if="!requiredFields.includes('media')">
            <slot name="optional-content"></slot>
          </div>

          <div
            class="col-12 media"
            v-if="requiredFields.includes('media')"
          >
            <p class="media-title">{{ mediaPlaceholder }}</p>

            <section
              v-if="selectedFile && selectedFileType === 'video/mp4'"
              id="vision"
              class="vision"
            >
              <video class="video" controls crossorigin playsinline>
                <source size="1080" :src="fileUrl" type="video/mp4" />
              </video>
            </section>
            <img
              v-else-if="selectedFile && selectedFileType === 'image/png'"
              class="media-img"
              :src="fileUrl"
            />
            <img
              v-else-if="selectedFile && selectedFileType === 'audio/mpeg'"
              class="media-img"
              src="https://cdn.pixabay.com/photo/2017/02/27/20/48/headphones-2104207_960_720.png"
            />
            <FormulateInput
              v-if="requiredFields.includes('media')"
              type="file"
              name="media"
              :help="labels.select_media_format"
              validation="required|mime:image/apng,image/avif,image/gif,image/jpeg,image/png,image/svg+xml,image/webp,video/mp4,video/ogv,video/flv,video/webm,video/asf,video/avi,audio/mpeg"
              @change="onFileChange"
              :validation-messages="{ required: labels.required }"
            />
          </div>
        </div>
      </FormulateForm>
    </template>

    <template v-slot:footer>
      <div class="create-entity__footer">
        <FormulateInput
          :disabled="submitBtnDisabled"
          type="submit"
          class="btn-purple"
          :label="labels.create"
          form="new-entity"
        />

        <Button
          :label="labels.close"
          variant="btn-outline-purple"
          data-dismiss="modal"
          style="fontweight: 600"
          :action="onModalClose"
        />
      </div>
    </template>
  </Popup>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";
import TextEditorCard from "@/components/Input/TextEditorCard";

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
  components: {
    Popup,
    Button,
    TextEditorCard,
  },
  data() {
    return {
      toggleStatus: false,
      fileUrl: "",
      selectedFile: "",
      selectedFileType: "",
      submitBtnDisabled: false,
    };
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
    parentKind: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    titlePlaceholder: {
      type: String,
      default: "",
    },
    textPlaceholder: {
      type: String,
      default: "",
    },
    mediaPlaceholder: {
      type: String,
      default: "",
    },
    formulateClass: {
      type: Object,
      default: () => {},
    },
    customFields: {
      type: Array,
      default: () => [],
    },
    requiredFields: {
      type: Array,
      default: () => [],
    },
    requiredDataSet: {
      type: Array,
      default: () => [],
    },
    dataSetValues: {
      type: Object,
      default: () => {},
    },
    routeBack: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    grid() {
      let grid = 12;
      if (this.requiredDataSet.includes("media")) grid = 6;
      return grid;
    },
    vuexModuleName() {
      if (["module", "export"].includes(this.kind)) return `_${this.kind}`;
      return this.kind;
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("monetizationBundle", {
      getMonetizationBundleList: "getMonetizationBundleList",
    }),
    ...mapMutations("helper", {
      setTablePagination: "setTablePagination",
    }),
    getMBList(page) {
      let data = { page };
      if (!this.$route.query.page) data.page = 1;
      else if (!page) data.page = this.$route.query.page;

      this.getMonetizationBundleList(data)
        .then((res) => {
          if (res.success) {
            this.setTablePagination(res.data.pages);
            if (
              res.data?.pages?.current &&
              this.$route?.query?.page &&
              Number(this.$route.query.page) !== Number(res.data.pages.current)
            ) {
              this.$router.push({
                query: { page: res.data.pages.current },
              });
            }
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    onModalClose() {
      if (this.routeBack && Object.keys(this.routeBack).length) {
        this.$router.push(this.routeBack);
      } else {
        this.$router.back();
      }
    },

    onFileChange(e) {
      const selectedFile = e.target.files[0]; // accessing file
      this.selectedFile = selectedFile;
      this.selectedFileType = selectedFile.type;
      this.fileUrl = URL.createObjectURL(selectedFile);
    },

    submitData(data) {
      this.submitBtnDisabled = true;

      let reqData = new FormData();

      if (this.requiredDataSet.includes("id"))
        reqData.append("id", this.$route.params.id);
      if (this.requiredDataSet.includes("title"))
        reqData.append("title", data.title);
      if (this.requiredDataSet.includes("text"))
        reqData.append("text", `${data.text}`);
      if (this.requiredDataSet.includes("textarea"))
        reqData.append("text", data.text);
      if (this.requiredDataSet.includes("description"))
        reqData.append("description", `${data.description}`);
      if (this.requiredDataSet.includes("media"))
        reqData.append("media", this.selectedFile);
      if (this.requiredDataSet.includes("parentId"))
        reqData.append("parentId", this.$route.params.id);
      if (this.requiredDataSet.includes("parentKind"))
        reqData.append("parentKind", this.parentKind);

      if (this.customFields.length) {
        this.customFields.forEach((item) => {
          if (item.type === "textarea") {
            if (data[item.name])
              reqData.append(item.name, `${data[item.name]}`);
          } else if (data[item.name])
            reqData.append(item.name, data[item.name]);
        });
      } else {
        this.requiredDataSet.forEach((item) => {
          if (data[item] && !reqData.has(item)) reqData.append(item, data[item]);
        });
      }

      if (this.dataSetValues) {
        Object.keys(this.dataSetValues).forEach((item) => {
          if (this.requiredDataSet.includes(item)) {
            if (
              this.dataSetValues[item].field &&
              this.requiredFields.includes(this.dataSetValues[item].field)
            ) {
              if (this.dataSetValues[item].field === "text") {
                reqData.append(item, `${data[this.dataSetValues[item].field]}`);
              } else {
                reqData.append(item, data[this.dataSetValues[item].field]);
              }
            } else {
              reqData.append(item, this.dataSetValues[item]);
            }
          }
        });
      }

      if (reqData.get("media") === null) {
        let newReqData = {};
        reqData.forEach((value, key) => (newReqData[key] = value));
        reqData = JSON.parse(JSON.stringify(newReqData));
        reqData.free = Boolean(reqData.free);
        reqData.kind = Number(reqData.kind);
      }

      this.$store
        .dispatch(`${this.vuexModuleName}/create`, reqData)
        .then((res) => {
          if (res.success) {
            this.toggleStatus = false;
            this.addNotification({
              variant: "success",
              msg: res.msg,
              labels: this.labels,
            });
            this.$emit("entity-created", res);
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
          this.submitBtnDisabled = false;
          return res;
        })
        .then((res) => {
          if (res.success) this.onModalClose();
        })
        .catch((err) => console.log(err));
      // this.onModalClose();
    },
  },
  mounted() {
    this.toggleStatus = true;

    let element = document.querySelector(".modal-backdrop");
    if (element) {
      element.remove();
      document.querySelector("body").classList.remove("modal-open");
    }
    // this.$(`#${this.modalId}`).modal('show');

    this.getMBList(this.$route.query.page);
  },
  beforeDestroy() {
    this.toggleStatus = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/formulate-input/button";
.create-entity {
  &__header-title {
    font-family: "Oswald", sans-serif;
  }

  &__body {
    ::v-deep .formulate-input {
      .formulate-input-wrapper {
        label {
          font-family: "Oswald", sans-serif;
          margin-bottom: 10px;
        }

        .formulate-input-element {
          max-width: 100%;
        }
      }

      &.horizontal-label-input {
        .formulate-input-wrapper {
          display: flex;
          align-items: center;
          gap: 3rem;

          .formulate-input-element {
            flex: 1 1 auto;
          }
        }
      }
    }

    .media-img {
      margin-bottom: 1.5em;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video {
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    align-items: center;

    ::v-deep .formulate-input {
      margin-bottom: 0px !important;
      .formulate-input-element {
        button {
          margin-bottom: 0px;
          margin-right: 15px;
        }
      }
    }
  }
}
::v-deep {
  .formulate-file {
    border-radius: 0 !important;
  }

  .formulate-input {
    width: 100%;
  }

  .formulate-input[data-classification="file"] .formulate-input-upload-area {
    position: static;
  }

  .formulate-input .formulate-file-upload-error {
    position: absolute;
    bottom: -25px;
  }

  .formulate-input-upload-area-mask {
    margin-top: 30px;
    left: 15px !important;
  }

  .col-12.col-md-6 {
    padding-right: 25px;
    padding-left: 0;
  }

  .modal-body {
    padding: 30px 40px;
  }
}

.media {
  display: flex;
  flex-direction: column;
  padding-left: 15px !important;
  padding-right: 15px !important;

  &-title {
    font-family: "Oswald", sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &-img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 50px;
    flex: 1 1 auto;
  }

  &-input {
    width: 100%;
  }
}
</style>
